import axios from "axios";

const update = async function(voucher, anulado) {
  try {
    const response = await axios.put(`/api/update_cancel_boleta/${voucher}`, anulado);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export default update;
