import fetchAll from "./fetchAll";
import fetchByVoucher from "./fetchByVoucher";
import fetchBydate from "./fetchBydate";
import cancel from "./cancel";

export default {
    fetchAll,
    fetchByVoucher,
    fetchBydate,
    cancel
}