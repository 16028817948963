import axios from "axios";

const fetchBydate = async function (inicio, fin, empresa) {
    try {
        const response = await axios.get(`/api/dates_boleta/${inicio}/${fin}/${empresa}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default fetchBydate;